import i18n from '@/plugins/localization/index'
export const headerStatisticTable = () => [
  { sortable: false, title: { global: i18n.t('totalGlobal') } },
  { value: 'id', text: i18n.t('tableHeaders.id'), sortable: false },
  { value: 'fullName', text: i18n.t('tableHeaders.fullName'), sortable: false },
  { value: 'role', text: i18n.t('tableHeaders.role'), sortable: false },
  { value: 'username', text: i18n.t('tableHeaders.username'), sortable: false },
  { value: 'verification', text: i18n.t('tableHeaders.submittedForVerification'), sortable: false, total: { global: 'sum_verification' } },
  { value: 'processed', text: i18n.t('tableHeaders.totalProcessingDocuments'), sortable: false, total: { global: 'sum_processed' } },
  { value: 'approved', text: i18n.t('tableHeaders.approved'), sortable: false, total: { global: 'sum_approved' } },
  { value: 'rejected', text: i18n.t('tableHeaders.rejected'), sortable: false, total: { global: 'sum_rejected' } }
]

export const headerStatisticTableExcels = () => [
  { value: 'file_name', text: i18n.t('nameDoc'), sortable: false },
  { value: 'created_at', text: i18n.t('createDate'), sortable: false },
  { value: 'event', text: i18n.t('save'), sortable: false, align: 'center' }
]

export const tabsStatisticPage = () => [
  { id: 1, link: { name: 'user-verification-statistics' }, label: 'statistics-user-verification' },
  { id: 2, link: { name: 'existing-doc-verification-statistics' }, label: 'statistics-existing-doc-verification' },
  { id: 3, link: { name: 'sailor-photo-verification-statistics' }, label: 'statistics-sailor-photo-verification' },
  { id: 4, link: { name: 'service-record-verification-statistics' }, label: 'statistics-service-record-verification' },
  { id: 5, link: { name: 'line-in-service-record-verification-statistics' }, label: 'statistics-line-in-service-record-verification' },
  { id: 6, link: { name: 'education-verification-statistics' }, label: 'statistics-education-verification' },
  { id: 7, link: { name: 'medical-certificate-verification-statistics' }, label: 'statistics-medical-certificate-verification' },
  { id: 8, link: { name: 'qualification-verification-statistics' }, label: 'statistics-qualification-verification' },
  { id: 9, link: { name: 'navigator-verification-statistics' }, label: 'statistics-navigator-verification' },
  { id: 10, link: { name: 'foreign-passport-verification-statistics' }, label: 'statistics-foreign-passport-verification' },
  { id: 11, link: { name: 'sailor-passport-verification-statistics' }, label: 'statistics-sailor-passport-verification' },
  { id: 12, link: { name: 'statistics-citizen-passport-verification' }, label: 'statistics-citizen-passport-verification' },
  { id: 13, link: { name: 'statistics-isc-user-verification' }, label: 'statistics-isc-user-verification' },
  { id: 14, link: { name: 'excel-verification-statistics' }, label: 'statistics-excel-verification' }
]
