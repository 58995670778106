<template lang="pug">
div.w-100.text-left.white
  h4.text-left.mb-10 {{ $t('statistics-verification') }}
    v-tabs(
      show-arrows
      next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline")
      v-tabs-slider(color="primary")
      v-tab(v-for="tab in items" :key="tab.id" :to="tab.link").p-2 {{$t(tab.label)}}
    router-view
</template>

<script>
import { mapMutations } from 'vuex'
import { tabsStatisticPage } from './constants'
export default {
  name: 'main-page-statistics',
  data () {
    return {
      items: tabsStatisticPage()
    }
  },
  methods: {
    ...mapMutations(['setActivePage'])
  },
  mounted () {
    this.setActivePage({ name: 'statistics-verification', access: true })
  }
}
</script>
